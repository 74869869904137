.time-picker-warning-container {
  position: relative;
}

.time-picker-container {
  position: relative;
  background-color: #293033;
  z-index: 999;

  .active-mask {
    position: absolute;
    width: 94%;
    height: 33%;
    background-color: rgba(255, 255, 255, 0.1);
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    border-radius: 10px;
  }

  .time-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 144px;
    position: relative;

    .picker {
      height: 144px;
      overflow: hidden;

      .swiper-container {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        .time {
          font-size: 16px;
          text-align: center;
          width: 48px;
          height: 48px;
          line-height: 48px;
          color: rgba(255, 255, 255, 0.7);
        }

        &.swiper-slide-active .time {
          color: #fff;
        }
      }
    }

    .time-colons {
      font-size: 16px;
      color: #fff;
    }
  }
}