.display-none {
  display: none !important;
}

span.thank_you_email {
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}

.thank_you_email-wrapper {
  align-items: center;
  flex: 0 1 70%;
}

.thank_you_email-content {
  border-radius: 24px;
  background: rgba(46, 55, 59, 0.75);
  backdrop-filter: blur(37.5px);
  min-width: 100%;
  padding: 70px 10% 40px 10%;
}