.error-filter__container {
  position: relative;
  display: flex;
  flex-direction: column;

  &:after {
    content: url('../../../assets/images/svg/arrow-down-for-native-select.svg');

    position: absolute;
    top: 29px;
    right: 7px;
  }

  select {
    appearance: none;
    padding: 0 5px;
  }

  span {
    color: #fff;
    font-size: 14px;
  }

  .additional-filters__select {
    cursor: pointer;
    color: #fff;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background-color: #293033;
    min-width: 200px;
    padding: 8px;
    margin-top: -3px;

    option {
      color: #9699a2;
    }
  }
}

.reboot-dropdown-wrapper {
  margin-right: 15px;

  .drop-down-list-reboot .drop-down-list-reboot-btn {
    max-height: 38px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.install-firmware-btn .is-large-button {
  max-height: 38px;
}