.cancel-button {
    min-width: 135px;
    display: inline-block;
    transition: none;
    text-align: center;
    background: transparent;
    white-space: nowrap;
    padding: 16px;
    line-height: 0.9;
    width: auto;
    margin: 0;

    border: 2px solid #fff;
    border-radius: 48px;
    font-size: 16px;
    font-weight: 700;

    color: #fff;
    cursor: pointer;

    box-shadow: 0 3px 20px 0 rgba(113, 106, 202, .17);

    &:hover:not(:disabled):not(.active) {
        border: 2px solid #fff;
        background: #fff;
        color: #000;
    }

    &:focus {
        border: 2px solid #fff;
        background: #fff;
        color: #000;
    }
}