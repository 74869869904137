.support-contracts-container {
  margin-top: 30px;
}

.support-contracts-container label {
  display: flex;
  align-items: center;
  line-height: 19px;
}

.support-contracts {
  font-size: 16px !important;
  font-weight: 400;
  color: #8f939b !important;
}

.release-group {
  color: #fff !important;
}

.support-contracts-info-text {
  display: block;
  padding-left: 30px;
  margin-bottom: 10px !important;
  color: #8f939b;
  font-size: 10px;
}

.support-contracts-margin>div>label {
  margin: 0 !important;
}

.support-contracts-container label.support-contracts span {
  background-color: transparent;
  border: 1px solid #FFE300;
}

.support-contracts input[type="checkbox"]:checked+span {
  background-color: #FFE300;
}

.support-contracts input[type="checkbox"]:disabled+span {
  opacity: 0.5;
}

.support-contracts-container label.support-contracts span::after {
  border-color: #293033 !important;
}

.software-update-btn {
  margin-left: 12px !important;

}

.software-update-btn.show-loader {
  padding-left: 0 !important;
  position: relative;
  display: flex !important;
  max-height: 30px;

  span:first-child {
    transform: scale(0.27);
    position: absolute;
    left: 7px !important;
    top: -20px !important;
  }
}

.export-button {
  gap: 20px;

  .headset-icon {
    margin-right: 5px;
  }
}

.support-btn-container {
  color: #494b4e;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  line-height: 20px;
}

.support-btn-tooltip {
  background: #fff !important;
  max-width: 370px;
}

.support-btn-popper {
  border-radius: 6px;
}

.support-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 9px 16px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 700;
  box-shadow: none;
  min-width: 145px;
  width: auto;
  border: 2px solid #FFF;
  color: #fff;
  background: transparent;

  &:hover:hover:not(:disabled):not(.active) {
    background: #FFF;
    border: 2px solid #FFF;
    color: #000;
    text-decoration: none;
  }
}

.dashboard-btn {
  margin-top: 0;
  float: right;
  line-height: 1;
  margin-bottom: 27px;
  text-transform: capitalize;
}

a.btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.dashboard-btn-disabled {
  cursor: default !important;
  pointer-events: none;
  border-color: #9699a2 !important;
  color: #9699a2 !important;
}

.tabs-panel {
  color: #fff;
}