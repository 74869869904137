$bg-color: #2E373B;
$today-color: #FFE300;
$last-7-days-color: #6B6A92;
$year-color: #B0D5C3;
$circle-marker-color: #fff;
$circle-marker-bg-color: rgba(255, 255, 255, 0.15);
$title-light-color: #fff;
$value-light-color: #fff;
$dark-light-color: #707070;

$status-ok-color: #1BBC9B;
$status-installation-ongoing-color: #3998f9;
$status-connected-color: #60CD27;
$status-warning-color: #ff9e22;
$status-error-color: #ED6200;
$status-not-connected-color: #DE3F1A;

@mixin set-responsive-property($lg, $lx, $xl-2, $value: padding) {
  #{$value}: $lg;

  @media (min-width: 1280px) {
    #{$value}: $lx;
  }

  @media (min-width: 1760px) {
    #{$value}: $xl-2;
  }
}

@mixin adaptive-value($start, $finish, $property: font-size) {
  $addSize: $finish - $start;
  $container: 1920;

  @media (min-width: #{1024 + px}) {
    #{$property}: $start + px;
    #{$property}: calc(#{$start + px} + #{$addSize * 0.7} * (100vw / #{$container}));
  }

  @media (min-width: #{$container + px}) {
    #{$property}: $finish + px;
  }
}