.rt-td.sm_id {
  font-weight: 400;
  color: #ffffff !important;
}

.rt-td {
  color: #ffffff !important;
  font-weight: 400;

  font-size: 16px;
  line-height: 2.5;
}

.action-buttons {
  text-align: right;
}

.preloader-container {
  height: 322px;
  display: flex;
  align-items: center;
}


.table-details {
  background-color: #2E373B;
  padding: 40px 0 19px 26px;
  position: relative;
}

.table-details.expanded-row {
  padding-top: 10px !important;
}

.table-row-details-head {
  color: #8f939b !important;
  font-size: 14px;
  font-weight: 400;

  padding-bottom: 10px;
}

.table-row-details-td {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.table-row-details-td.email-detail-table {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #FFE300;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.email-detail-table:hover {
  text-decoration: underline;
}

.dashboard-link {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  position: absolute;
  top: 34px;
  left: 88%;
}

.error_status_yes {
  font-size: 16px;
}

.acc_status-td {
  font-weight: 400 !important;
}

.la.la-info-circle {
  margin-right: 3px;
  margin-left: 3px;
}

.last_login-td {
  font-weight: 400 !important;
}

.full_name-avatar-td {
  margin-right: 15px;
}

.email-text-td a {
  color: #FFE300 !important;
  font-size: 16px;
  font-weight: 400;

}

.phone_num-td,
.city-td {
  font-weight: 400;
}

.rt-th {
  position: relative;
}

.rt-th input[type='checkbox'] {
  position: absolute;
  top: 19px;
  left: 9px;
}

.white {
  color: #fff
}

.disabled {
  opacity: 0.5;
}

.m-form .m-form__group.radio-oem label {
  font-size: 14px !important;
}

.top-control-tab {
  grid-row-gap: 10px;
}