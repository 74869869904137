.is-large-button {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.65rem 1rem;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  box-shadow: none;

    &:hover:hover:not(:disabled):not(.active) {
        background: #fff;
        border: 2px solid #fff;
        box-shadow: none;
        color: #000;
        text-decoration: none;
        cursor: pointer;
        transition: none;
    }

  &:disabled {
    border-color: #767676 !important;
    color: #767676 !important;
  }
}
