.switching-times {
    &-time-picker {
        color: var(--primary-color);

        .inputRoot {
            &:before {
                border-bottom: 1px solid #5e6774 !important;
            }

            &:after {
                border-bottom: 1px solid var(--primary-color) !important;
            }
        }

        .opened__time-picker {
            &:after {
                border-bottom: 1px solid #5e6774 !important;
            }
        }
    }

    &-label {
        font-size: 11px;
        font-weight: 400;
    }
}