.edit-column-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
  margin-top: 0.3em;

  .edit-ico {
    fill: var(--light-dark-color);
  }

  button:hover {
    .edit-ico {
      fill: #000;
    }
  }
}