.load-guard-tab-container {
  margin-left: 130px !important;

  i.la.la-info-circle {
    margin-bottom: 3px;
  }
}

.devices-tab-panel {
  position: absolute !important;
  right: 0;
  top: -50px;

  .btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst {
    min-width: 160px;
    width: auto;
    padding: 13px 16px;
    font-size: 14px;
    border: 2px solid #FFF !important;
    color: #fff;
    background: transparent;

    &:hover:not(:disabled):not(.active) {
      background: #FFF !important;
      border: 2px solid #FFF !important;
      color: #000;
    }
  }
}

.fakeField {
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.load-guard-tooltip {
  div.MuiTooltip-tooltip {
    background-color: #25282e;
  }
}

.form-group.m-form__group.input-field.switchingTimes-field {
  height: auto !important;
}

@media (max-width: 1380px) {
  .load-guard-tab-container {
    margin-left: 0 !important;
  }

  .react-tabs {
    .loadguards {
      margin-bottom: 60px;
    }
  }
}

.sub-meter {
  .react-tabs__tab-list {
    border: none !important;
    margin-top: 20px !important;
  }

  .nav-link.m-tabs__link:hover {
    color: #fff !important;
    border-bottom: #fff !important;
    cursor: default;
  }
}

.sub-meter-data-list {
  .rt-thead.-header {
    display: none !important;
  }
}