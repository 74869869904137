.additional-filters__container {
  padding: 24px 0;
  background-color: #293033;
  column-gap: 35px;
  row-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .multi-select {
    margin: 0 !important;
  }

  .installation-finished-filter__container,
  .support-contracts-filter__container,
  .filter__container {
    position: relative;
    display: flex;
    flex-direction: column;

    &:after {
      content: url('../../assets/images/svg/arrow-down-for-native-select.svg');

      position: absolute;
      top: 36px;
      right: 7px;
    }

    select {
      appearance: none;
      padding: 4px 5px;
    }

    span {
      color: #fff;
      font-size: 14px;
    }

    .additional-filters__select {
      cursor: pointer;
      border-radius: 8px;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.50);
      background-color: transparent;
      width: 200px;
      padding: 10px 10px 10px 18px;

      option {
        color: #FFFFFF;
        background-color: #2E373B;
      }
    }
  }

  .additional-filters__reset-btn {
    color: #212529;
    font-size: 13px;
    font-weight: 400;
    height: 40px;
    line-height: 19.5px;
    padding: 0px;
    margin-left: auto;

    &.disable-button {
      button {
        background: rgba(255, 255, 255, 0.50);
        border: 2px solid transparent !important;
        color: rgba(0, 0, 0, 0.50) !important;
      }
    }
  }
}